import { useEffect } from "react";
import { useIsFetching } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

function useSpinner() {
	const isFetching = useIsFetching();

	useEffect(() => {
		let toastId: string;

		if (isFetching) {
			toastId = toast.loading("Fetching...", {
				position: "top-center",
			});
		}
		return () => {
			toast.dismiss(toastId);
		};
	}, [isFetching]);
}

export default useSpinner;
