import { useMemo } from "react";
import { LocalBusinessJsonLd } from "next-seo";
import { useBusinessProfile } from "hooks/useFetch";
import useSortWorkingHours from "hooks/useSortWorkingHours";
import useTranslation from "next-translate/useTranslation";

const DOMAIN_URL = process.env.NEXT_PUBLIC_DOMAIN_URL;

const StoreJsonLd = () => {
	const { t } = useTranslation("common");
	const { data: businessProfile } = useBusinessProfile();
	const profile = businessProfile?.data;
	const location = profile?.locations.items[0].address;
	const workingHours = profile?.workingHours;
	const review = profile?.reviews.items;
	const service = profile?.services.items;
	const locations = profile?.locations.items;
	const images = profile?.coverImages.map((x) => x.url);
	const description = profile?.description || t("sections.about.description");

	const sorting = useSortWorkingHours(workingHours);
	const openingHours = useMemo(
		() =>
			sorting
				.filter((x) => x.time[0])
				.map((x) => ({ opens: x.time[0].start, closes: x.time[0].end, dayOfWeek: x.d })),
		[sorting]
	);

	const reviews = useMemo(
		() =>
			review?.map((re) => ({
				author: `${re.author.firstName} ${re.author.lastName}`,
				datePublished: re.publishedAt,
				reviewBody: re.comment,
				reviewRating: {
					ratingValue: re.score,
				},
			})),
		[review]
	);

	const areas = useMemo(
		() =>
			locations?.map((loc) => ({
				geoMidpoint: {
					latitude: loc.address.geoLocation.latitude,
					longitude: loc.address.geoLocation.longitude,
				},
				geoRadius: 1000,
			})),
		[locations]
	);

	const makesOffer = useMemo(
		() =>
			service?.map((se) => ({
				priceSpecification: {
					type: "UnitPriceSpecification",
					priceCurrency: profile?.currency.name,
					price: `${se.price}`,
				},
				itemOffered: {
					name: se.name,
					description: se.description,
				},
			})),
		[profile?.currency.name, service]
	);

	return (
		<LocalBusinessJsonLd
			type="Store"
			id={`${DOMAIN_URL}/b/${profile?.key}`}
			name={profile?.name || ""}
			description={description || ""}
			url={`${DOMAIN_URL}/b/${profile?.key}`}
			telephone={`+${profile?.phoneNumber?.countryCode}${profile?.phoneNumber?.number || ""}`}
			address={{
				streetAddress: location?.displayAddress,
				addressLocality: location?.city,
				addressRegion: location?.state,
				postalCode: location?.postalCode,
				addressCountry: location?.country,
			}}
			geo={{
				latitude: location?.geoLocation.latitude,
				longitude: location?.geoLocation.longitude,
			}}
			images={images}
			// sameAs={["www.company-website-url1.dev", "www.company-website-url2.dev", "www.company-website-url3.dev"]}
			openingHours={openingHours}
			rating={{
				ratingValue: `${profile?.rate.score}`,
				ratingCount: `${profile?.rate.count}`,
			}}
			review={reviews}
			makesOffer={makesOffer}
			areaServed={areas}
			// action={{
			// 	actionName: "potentialAction",
			// 	actionType: "ReviewAction",
			// 	target: "https://www.example.com/review/this/business",
			// }}
		/>
	);
};

export { StoreJsonLd };
