import React, { useMemo, memo } from "react";
import Head from "next/head";
import Script from "next/script";
import dynamic from "next/dynamic";
import { NextSeo } from "next-seo";
import useColorTheme from "hooks/useColorTheme";
import useSpinner from "hooks/useSpinner";
import { generateOGUrl } from "utils/aes.utils";
import { StoreJsonLd } from "containers/JSON-LD/JSON-LD";
import { resolvedPublicPath, truncateString } from "utils/utils";
import { AvailableLanguages } from "types/business.types";

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const Header = dynamic(() => import("containers/Layout/Header"), {
	ssr: false,
});

type Props = {
	children: React.ReactNode;
	businessKey?: string;
	title: string;
	hideHeaderFooter?: boolean;
	description?: string;
	primaryColor?: string;
	logoURL?: string;
	headerProps?: { onHeaderRefChange: any };
	languages?: AvailableLanguages[];
};

const Layout = ({
	children,
	businessKey,
	title,
	description,
	hideHeaderFooter,
	primaryColor,
	logoURL,
	headerProps,
	languages,
}: Props) => {
	useColorTheme(primaryColor);
	useSpinner();

	const cx = generateOGUrl({
		title,
		primaryColor,
		logoURL,
	});

	const truncatedDesc = description && truncateString(description, 160);
	const languageAlternates = useMemo(
		() =>
			languages?.map((l) => ({
				hrefLang: l,
				href: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/b/${l}/${businessKey}`,
			})),
		[languages, businessKey]
	);

	return (
		<>
			<Head>
				<link rel="apple-touch-icon" sizes="180x180" href={resolvedPublicPath("apple-touch-icon.png")} />
				<link rel="icon" type="image/png" sizes="32x32" href={resolvedPublicPath("favicon-32x32.png")} />
				<link rel="icon" type="image/png" sizes="16x16" href={resolvedPublicPath("favicon-16x16.png")} />

				{/* <link rel="manifest" href={resolvedPublicPath("manifest.json")} /> */}
			</Head>
			<Script
				id="gtag-base"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
				}}
			/>

			<NextSeo
				title={title}
				description={truncatedDesc}
				canonical={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/b/${businessKey}`}
				noindex={process.env.NEXT_PUBLIC_NO_INDEX === "true"}
				languageAlternates={languageAlternates}
				openGraph={{
					url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/b/${businessKey}`,
					title,
					description: truncatedDesc,
					images: [
						{
							url: `${process.env.NEXT_PUBLIC_OG_COVER_URL}/api/og?cx=${cx}`,
							width: 1200,
							height: 630,
							alt: title,
							type: "image/png",
						},
					],
				}}
				twitter={{
					handle: "@handle",
					site: "@site",
					cardType: "summary_large_image",
				}}
			/>

			{!hideHeaderFooter && <StoreJsonLd />}
			<main className="text-black">
				{!hideHeaderFooter && <Header {...headerProps} />}
				<>{children}</>
				<noscript>
					<iframe
						title="googletagmanager"
						src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
						height="0"
						width="0"
						style={{ display: "none", visibility: "hidden" }}
					/>
				</noscript>
			</main>
		</>
	);
};

export default memo(Layout);
