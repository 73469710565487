const secretKey = process.env.NEXT_PUBLIC_SECRET_KEY_OG as string;

const cipher = (salt: string) => {
	const textToChars = (text: string) => text.split("").map((c) => c.charCodeAt(0));
	const byteHex = (n: number) => ("0" + Number(n).toString(16)).substr(-2);
	const applySaltToChar = (code: number) => textToChars(salt).reduce((a, b) => a ^ b, code);

	return (text: any) => text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
};

const decipher = (salt: string) => {
	const textToChars = (text: string) => text.split("").map((c) => c.charCodeAt(0));
	const applySaltToChar = (code: number) => textToChars(salt).reduce((a, b) => a ^ b, code);
	return (encoded: any) =>
		encoded
			.match(/.{1,2}/g)
			.map((hex: string) => parseInt(hex, 16))
			.map(applySaltToChar)
			.map((charCode: number) => String.fromCharCode(charCode))
			.join("");
};

export const encrypt = cipher(secretKey);
export const decrypt = decipher(secretKey);

type OGType = {
	title?: string;
	primaryColor?: string;
	logoURL?: string;
};

export const generateOGUrl = ({ title, primaryColor, logoURL }: OGType) =>
	encrypt(JSON.stringify({ title, primaryColor, logoURL }));
