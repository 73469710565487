import { useEffect } from "react";
import { readableColor, transparentize } from "color2k";

function useColorTheme(primaryColor?: string) {
	useEffect(() => {
		if (primaryColor) {
			const root = document.documentElement;

			const readablecolor = readableColor(primaryColor);

			root.style.setProperty("--primary-500", primaryColor || "");
			root.style.setProperty("--primary-300", transparentize(primaryColor, 0.3) || "");
			root.style.setProperty("--primary-50", transparentize(primaryColor, 0.85) || "");

			// It's too weird, but the Design team can do it.
			if (primaryColor === "#536DFE") {
				root.style.setProperty("--primary-readable-color", "#ffffff");
			} else {
				root.style.setProperty("--primary-readable-color", readablecolor || "");
			}
		}
	}, [primaryColor]);
	return;
}

export default useColorTheme;
